import {
  AchievementComponent,
  AchievementSubtitleComponent,
  AchievementTitleComponent,
} from "./AchievementComponents";

interface AchievementProps {
  title: string;
  subtitle: string;
}

const Achievement = ({ title, subtitle }: AchievementProps) => {
  return (
    <AchievementComponent data-aos="fade-up" >
      <AchievementTitleComponent>{title}</AchievementTitleComponent>
      <AchievementSubtitleComponent>{subtitle}</AchievementSubtitleComponent>
    </AchievementComponent>
  );
};

export default Achievement;
