import { ButtonHTMLAttributes } from "react";
import { ButtonComponent } from "./ButtonComponents";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & { $padding?: string, $height?: string, $fontSize?: string };

const Button = ({ ...props }: ButtonProps) => (
  <ButtonComponent {...props}>{props.children}</ButtonComponent>
);

export default Button;
