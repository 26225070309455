import styled from "styled-components";
import { colors } from "../../styles/colors";
import { screenSize } from "../../styles/screenSize";
import experienceImage from "../../assets/images/experienceImage.jpg";

export const ContainerComponent = styled.div`
  padding: 120px 0 180px;

  @media (max-width: ${screenSize.laptop}) {
    padding: 80px 0 120px;
  }
`;

export const TitleContainerComponent = styled.div`
  width: 880px;

  @media (max-width: ${screenSize.tablet}) {
    width: auto;
  }

  @media (max-width: ${screenSize.mobile}) {
    margin-bottom: 64px;
  }
`;

export const TitleComponent = styled.p`
  color: ${colors.neutral800};
  font-family: "Ubuntu";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.laptop}) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const ColoredTitleComponent = styled(TitleComponent)`
  color: ${colors.primary300};
  display: inline;
`;

export const ContentContainerComponent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: ${screenSize.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SubtitleContainerComponent = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${screenSize.laptop}) {
    width: 452px;
  }
  
  @media (max-width: ${screenSize.mobile}) {
    width: 343px;
  }
`;

export const ImageComponent = styled.div`
  margin-top: 80px;
  background-size: cover;
  border-radius: 10px;
  background-image: url(${experienceImage});
  background-repeat: no-repeat;
  width: 660px;
  height: 440px;

  @media (max-width: ${screenSize.desktop}) {
    max-width: 428px;
    height: 286px;
  }

  @media (max-width: ${screenSize.tablet}) {
    max-width: 318px;
    height: 212px;
  }
  
  @media (max-width: ${screenSize.mobile}) {
    max-width: 343px;
    height: 343px;
    margin-top: 0;
  }
`;
