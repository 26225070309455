import { ReactElement } from "react";
import { CardComponent, CardTitleComponent } from "./SolutionComponents";
import { SectionSubtitleComponent } from "../../styles/CommonComponents";

interface SolutionCardProps {
  icon: ReactElement;
  title: string;
  subtitle: string;
}

const SolutionCard = ({ icon, title, subtitle }: SolutionCardProps) => {
  return (
    <CardComponent data-aos="fade-up">
      {icon}
      <CardTitleComponent>{title}</CardTitleComponent>
      <SectionSubtitleComponent>{subtitle}</SectionSubtitleComponent>
    </CardComponent>
  );
};

export default SolutionCard;
