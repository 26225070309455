import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const ButtonComponent = styled.button<{ $padding?: string, $height?: string, $fontSize?: string }>`
  width: 100%;
  display: flex;
  height: ${(props) => (props.$height ? props.$height : "44px")};
  min-width: 120;
  padding: ${(props) => (props.$padding ? props.$padding : "7px 12px")};
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 200px;
  border: none;
  background: ${colors.primary300};
  color: ${colors.neutral};

  font-family: Ubuntu;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : "16px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
