import { ReactComponent as LogoIcon } from "../../assets/icons/letoLogo.svg";
import {
  ColoredSectionSubtitleComponent,
  LiComponent,
  NavComponent,
  NavLink,
  SectionSubtitleComponent,
  UlComponent,
} from "../../styles/CommonComponents";
import {
  AddressComponent,
  FooterContainerComponent,
  InfoComponent,
  InfoComponentContainer,
} from "./FooterComponents";

const Footer = () => {
  return (
    <FooterContainerComponent>
      <InfoComponentContainer>
        <LogoIcon />

        <InfoComponent>
          <ColoredSectionSubtitleComponent>
            support@leto.center
          </ColoredSectionSubtitleComponent>
          <AddressComponent>
            <SectionSubtitleComponent>
              15/2 Amiryan street
            </SectionSubtitleComponent>
            <SectionSubtitleComponent>
              Kentron 0002 Yerevan Armenia
            </SectionSubtitleComponent>
          </AddressComponent>
        </InfoComponent>
      </InfoComponentContainer>

      <NavComponent $isShown>
        <UlComponent $direction="column" $gap="8px" $position="flex-end">
          <LiComponent>
            <NavLink href="#solution-section">Solution</NavLink>
          </LiComponent>
          <LiComponent>
            <NavLink href="#experience-section">Experience</NavLink>
          </LiComponent>
        </UlComponent>
      </NavComponent>
    </FooterContainerComponent>
  );
};

export default Footer;
