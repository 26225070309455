import { SectionTitleComponent } from "../../styles/CommonComponents";
import SolutionCard from "./SolutionCard";
import {
  CardsContainerComponent,
  ContainerComponent,
} from "./SolutionComponents";
import { ReactComponent as FinanceIcon } from "../../assets/icons/finance.svg";
import { ReactComponent as BrandingIcon } from "../../assets/icons/branding.svg";
import { ReactComponent as MarketingIcon } from "../../assets/icons/marketing.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { ReactComponent as MobileIcon } from "../../assets/icons/mobile.svg";
import { ReactComponent as WebIcon } from "../../assets/icons/web.svg";

const solutionCardsParamsList = [
  {
    key: "branding-strategy",
    icon: <BrandingIcon />,
    title: "Branding & Strategy",
    subtitle:
      "Creating a distinctive brand by developing its name, logo, visual identity, along with a promotion strategy and seamless integration into your digital products.",
  },
  {
    key: "marketing",
    icon: <MarketingIcon />,
    title: "Marketing",
    subtitle:
      "Creating digital marketing, content marketing, and SEO strategies, executing advertising campaigns, and evaluating results to drive your business growth.",
  },
  {
    key: "ux-ui",
    icon: <UserIcon />,
    title: "UX/UI",
    subtitle:
      "Designing user-friendly interfaces and exceptional user experiences, enhancing product interaction to boost customer satisfaction and loyalty.",
  },
  {
    key: "mobile",
    icon: <MobileIcon />,
    title: "Mobile",
    subtitle:
      "Creating applications for iOS and Android, spanning from conceptualization to market release, prioritizing user experience and functionality.",
  },
  {
    key: "web",
    icon: <WebIcon />,
    title: "Web",
    subtitle:
      "Creating top-notch and practical websites and web applications that adapt seamlessly to all devices, employing state-of-the-art technologies and adhering to industry best practices.",
  },
  {
    key: "finance-legal",
    icon: <FinanceIcon />,
    title: "Finance & Legal",
    subtitle:
      "We offer financial advisory, legal safeguarding, contract and license formulation, ensuring the legal integrity of your business.",
  },
];

const Solution = () => {
  return (
    <ContainerComponent id="solution-section">
      <SectionTitleComponent data-aos="fade-up" >Our Solution</SectionTitleComponent>

      <CardsContainerComponent>
        {solutionCardsParamsList.map((card) => (
          <SolutionCard {...card} />
        ))}
      </CardsContainerComponent>
    </ContainerComponent>
  );
};

export default Solution;
