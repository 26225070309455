import Button from "../ui/button/Button";
import {
  ColoredSubtitleComponent,
  ContainerComponent,
  ImageContainer,
  SectionContainerComponent,
  SubtitleComponent,
  TitleComponent,
} from "./TitleComponents";
import { ReactComponent as SunIcon } from "../../assets/icons/sun.svg";
import { NavLink } from "../../styles/CommonComponents";

const Title = () => {
  return (
    <ContainerComponent>
      <SectionContainerComponent>
        <TitleComponent>LETO</TitleComponent>

        <SubtitleComponent>
          We’re prepared to be your personal guide through the software journey,&nbsp;
          <ColoredSubtitleComponent>
            bringing your app or website to life.
          </ColoredSubtitleComponent>
        </SubtitleComponent>

        <NavLink href="#contact-section">
          <Button $padding="12px 28px">Talk to us</Button>
        </NavLink>
      </SectionContainerComponent>

      <ImageContainer>
        <SunIcon />
      </ImageContainer>
    </ContainerComponent>
  );
};

export default Title;
