import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { HeaderComponent } from "./HeaderComponents";
import Button from "../ui/button/Button";
import {
  LiComponent,
  NavComponent,
  NavLink,
  UlComponent,
} from "../../styles/CommonComponents";
import BurgerMenu from "../ui/burgerMenu/BurgerMenu";

const Header = () => {
  return (
    <HeaderComponent>
      <LogoIcon style={{ zIndex: 20 }} />

      <NavComponent>
        <UlComponent $direction="row">
          <LiComponent>
            <NavLink href="#solution-section">Solution</NavLink>
          </LiComponent>
          <LiComponent>
            <NavLink href="#experience-section">Experience</NavLink>
          </LiComponent>
          <LiComponent>
            <NavLink href="#contact-section">Contacts</NavLink>
          </LiComponent>
          <LiComponent>
            <NavLink href="#contact-section">
              <Button $fontSize="14px" $height="32px">Get in touch</Button>
            </NavLink>
          </LiComponent>
        </UlComponent>
      </NavComponent>

      <BurgerMenu />
    </HeaderComponent>
  );
};

export default Header;
